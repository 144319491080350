import tippy from "tippy.js";

var $ = jQuery.noConflict();

// Tooltips
function initTooltips() {
  tippy("[data-tippy-content]", {
    placement: "bottom",
    allowHTML: true,
    interactive: true,
    animation: "fade",
  });
}
initTooltips();

async function handleFinderSearch(e) {
  try {
    e.preventDefault();

    $(".js-search-form .form__button").addClass("loading");
    
    const isArchive = document
      .querySelector("body")
      .classList.contains("page-template-tpl-hns-archive");

    let formData = new FormData(this);
    let searchParams = new URLSearchParams(formData);
    let queryString = "";

    let i = 0;
    for (const [key, value] of searchParams) {
      queryString += i === 0 ? `${key}=${value}` : `&${key}=${value}`;
      i++;
    }

    // /hns-finder/search/ has to be hardcoded as we don't want to include pages e.g. /page/2/ so we can't use window.location.pathname
    let pushstateUrl = isArchive
      ? `/hns-finder/archive/?${queryString}`
      : `/hns-finder/search/?${queryString}`;
    window.history.pushState({}, "", pushstateUrl);

    let urlStructure = isArchive
      ? `/hns-finder/archive/${window.location.search}`
      : `/hns-finder/search/${window.location.search}`;
    formData.append("action", "ajax_get_search_results");
    formData.append("url_structure", urlStructure);
    formData.append("is_archive", isArchive);

    const response = await fetch(php_vars.ajaxUrl, {
      method: "post",
      body: formData,
    });
    const data = await response.text();

    if (response.status !== 200) throw new Error(response);

    let dataDestination = document.querySelector(
      ".js-search-results-container"
    );

    dataDestination.innerHTML = data;

    // if archive, set the year text at the top
    if (isArchive) {
      let yearElement = document.querySelector(".js-archive-year");
      let yearSelect = document.querySelector("select#release");
      let yearSelectOptions = [...yearSelect.querySelectorAll("option")];

      let selectedYear = yearSelectOptions[yearSelect.selectedIndex];

      yearElement.innerHTML = selectedYear.text;
    }

    // re initialise tooltips
    initTooltips();

    $(".js-search-form .form__button").removeClass("loading");
  } catch (err) {
    console.log(err);

    $(".js-search-form .form__button").removeClass("loading");
  }
}

// make a popstate handler for going back
if (
  document
    .querySelector("body")
    .classList.contains("page-template-tpl-hns-search") ||
  document
    .querySelector("body")
    .classList.contains("page-template-tpl-hns-archive")
) {
  document
    .querySelector(".js-search-form")
    .addEventListener("submit", handleFinderSearch, false);
}

jQuery(function ($) {
  highlightImplementationOnSinglePage: {
    if (document.querySelector("body").classList.contains("single-activity")) {
      let menuItems = document.querySelectorAll(
        "#menu-item-22, #menu-item-6602, #menu-item-6603"
      );

      for (let i = 0; i < menuItems.length; i++) {
        menuItems[i].classList.add("current-page-ancestor");
      }
    }
  }
  hamburgerMenu: {
    $(".js-navbar-toggle").on("click", function () {
      if ($(this).hasClass("js-hidden")) {
        $(".js-mobile-menu").slideDown();
        $(this).removeClass("js-hidden");
      } else {
        $(".js-mobile-menu").slideUp();
        $(this).addClass("js-hidden");
      }
    });
  }
  hnsSearchResultsMergeRows: {
    $(".merged").each(function () {
      //            console.info($(this));

      var row = $(this).prev("tr");

      row.find(".col-name").attr("rowspan", "2");
      //            $(this).removeClass("odd");
    });
  }
  languageDropdown: {
    $(".language").on("click", function () {
      console.info("clicked");
      $(this).find("ul").toggleClass("display");
    });
  }
  slider: {
    $(".hero__slider").slick({
      infinite: true,
      dots: false,
      arrows: false,
      fade: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 7000,
    });
  }
  anchorlinks: {
    $(document).on("click", ".news-slider__nav a", function (event) {
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top,
        },
        500
      );
    });
  }
  fixedheader: {
    $(function () {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 150) {
          $(".header").addClass("fixed-header");
        }
        if ($(window).scrollTop() >= 160) {
          $(".header").addClass("transform-none");
        } else {
          $(".header").removeClass("fixed-header");
          $(".header").removeClass("transform-none");
        }
      });
    });
  }
  mobile: {
    ///MOBILE MENU - FOR USE WITH TWK_NAV_WALKER
    //                $('.menu .menu-item-has-children > a').click(function (event) {
    //                    event.preventDefault();
    //                    $(this).parent().children('li .sub-menu-wrap').addClass('transform-none');
    //                });
    //                $('.back-menu').click(function () {
    //                    $(this).parent().parent().removeClass('transform-none');
    //                });
  }
  magnific: {
    ///IFRAME
    $(".magnific-video").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }
  externallinks: {
    $("a:not(.magnific-video)").each(function () {
      var a = new RegExp("/" + window.location.host + "/");
      if (!a.test(this.href)) {
        $(this).click(function (event) {
          event.preventDefault();
          event.stopPropagation();
          window.open(this.href, "_blank");
        });
      }
    });
    pdfs: {
      ///OPEN PDFS IN NEW WINDOW
      $(function () {
        $('a[href$=".pdf"]').prop("target", "_blank");
      });
    }
  }
  search: {
    $(".search-icon").on("click", function (e) {
      $(".searchform").toggleClass("search-open");
      $(this).toggleClass("submit-zindex");
      $(".select-lang").removeClass("transform-height");
      $("#menu-main-menu-top, .lang_dropdown").fadeOut();
      $("#s").focus();
      setTimeout(function () {
        $(".close-search").fadeIn();
      }, 300);
    });
    $(".close-search").on("click", function (e) {
      $(this).fadeOut();
      $(".searchform").removeClass("search-open");
      $(".search-icon").removeClass("submit-zindex");
      $("#menu-main-menu-top, .lang_dropdown").fadeIn();
    });
  }
  loadingAnimations: {
    $.fn.isOnScreen = function () {
      var win = $(window);
      var viewport = {
        top: win.scrollTop(),
      };
      viewport.bottom = viewport.top + win.height() - 100;
      var bounds = this.offset();
      bounds.bottom = bounds.top + this.outerHeight();
      return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
    };
    $(window).scroll(function () {
      $(".off-screen").each(function (index) {
        if ($(this).isOnScreen()) {
          $(this).removeClass("off-screen--hide");
        }
      });
    });
  }
  onLoad: {
    $(document).ready(function () {
      $(".off-screen").each(function (index) {
        if ($(this).isOnScreen()) {
          $(this).removeClass("off-screen--hide");
        }
      });
    });
  }
  download_HNS_CSV_onClick_Ajax: {
    $(
      "body.page-template-tpl-hns-search, body.page-template-tpl-hns-archive"
    ).on("click", "#hns_csv_download", function (e) {
      e.preventDefault();

      let includeSynonyms =
        $(".floating-export #include-synonyms") &&
        $(".floating-export #include-synonyms").is(":checked");
      const isArchive = document
        .querySelector("body")
        .classList.contains("page-template-tpl-hns-archive");

      //            document.location = "/wp-content/themes/HNS_Convention/tpl/parts/hns-export-download.php" + window.location.search;
      document.location = `/wp-content/themes/HNS_Convention/tpl/parts/hns-export-download.php${
        window.location.search
      }${includeSynonyms ? "&include_synonyms=1" : ""}${
        isArchive ? "&is_archive=1" : ""
      }`;
    });
  }
  accordion: {
    function toggleAccordion() {
      $(".js-accordion-target")
        .not($(this).find(".js-accordion-target"))
        .slideUp();
      $(".js-accordion-plus")
        .not($(this).find(".js-accordion-plus"))
        .removeClass("active");
      $(this).find(".js-accordion-target").slideToggle();
      $(this).find(".js-accordion-plus").toggleClass("active");
    }

    let accordions = document.querySelectorAll(
      ".accordion:not(.accordion--document-library)"
    );

    for (let i = 0; i < accordions.length; i++) {
      accordions[i].addEventListener("click", toggleAccordion, false);
    }
  }
  documentLibrary: {
    // reorder form elements on document library
    if ($(".page-template-tpl-document-library").length > 0) {
      const form = $("form.searchandfilter");
      const results = $(".search-filter-results");

      // Search fields
      const searchField = $(".sf-field-search");
      const firstSubmitField = $(".sf-field-submit")[0];

      // Filter fields
      const dateField = $(".sf-field-post-meta-document_date");
      const authorField = $(".sf-field-taxonomy-documentauthor");
      const orderField = $(".sf-field-sort_order");
      const secondSubmitField = $(".sf-field-submit")[1];

      form.addClass("form form--search-filter");
      $(firstSubmitField).find("input").addClass("form__button");
      $(secondSubmitField).find("input").addClass("form__button");

      searchField
        .add(firstSubmitField)
        .wrapAll('<div class="form__search"></div>');
      dateField
        .add(authorField)
        .add(orderField)
        .add(secondSubmitField)
        .wrapAll(
          '<div class="form__filter"><div class="form__filter__fields"></div></div>'
        );

      let filterResultsTitle = '';
      if (php_vars.wpmlCurrentLanguage == 'en') {
        filterResultsTitle = 'Filter and sort results';
      } else if (php_vars.wpmlCurrentLanguage == 'es') {
        filterResultsTitle = 'Filtrar y ordenar resultados';
      } else if (php_vars.wpmlCurrentLanguage == 'fr') {
        filterResultsTitle = 'Filtrer et trier les résultats';
      }

      $(".form__filter").prepend('<h2 class="form__title">' + filterResultsTitle + '</h2>');

      form.css("opacity", "1");
      results.css("opacity", "1");
    }
  }
});

const accountSectors = {
  oil: {
    "persistent-oil": "Persistent Oil",
    "non-persistent-oil": "Non persistent Oil",
  },
  general: {
    "other-hns": "Other HNS",
    "bulk-solids": "Bulk Solids",
  },
};

$(document).ready(function () {
  $(".js-search-form").on("change", "#account", function (e) {
    let value = $(this).val();
    if (value == "oil" || value == "general") {
      var html = '<option value="" selected>All sectors</option>';
      var sectors = accountSectors[value];
      for (const key in sectors) {
        html += '<option value="' + key + '">' + sectors[key] + "</option>";
      }
      $(".js-search-form #sector").html(html);
      $(".js-search-form #sector").prop("disabled", false);
    } else {
      $(".js-search-form #sector").prop("disabled", true);
      $(".js-search-form #sector").val("");
      $(".js-search-form #sector").html(
        '<option value="" selected>All sectors</option>'
      );
    }
  });
});
